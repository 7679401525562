<template>
  <div class="achievment" :class="className">
    <div class="achievment__img">
      <Skeleton v-if="data.isPictureLoading && !data.image" />
      <img
        :src="imgSrcWithHash"
        alt="Portfolio image"
        v-else
        @click="openPopup"
      />
    </div>
    <div class="achievment__label">
      <a @click="openPopup" class="achievment__name">
        {{ data.title }}
      </a>
      <span class="achievment__name-year"> {{ data.year }} год </span>
    </div>

    <div class="achievment__date">
      <p>Файл загружен пользователем: {{ momentData }}</p>
      <p class="achievment__date--new" v-if="data?.isPush">Успешно загружено</p>
    </div>
    <p v-if="applicationsId">
      Участвует в заявке №
      <router-link :to="{ name: 'Applications' }">
        {{ applicationsId }}
      </router-link>
    </p>
    <Button
      v-else
      @click="openPopupDelete"
      class="achievment__btn"
      secondary
      :loading="isDeleteBtnLoading"
      :disabled="data?.isPush"
    >
      Удалить
    </Button>
    <PopupViewCertificate
      v-if="isPopupOpen"
      :portfolio_image="data?.push_image"
      :image_url="data?.url_original"
      @close-popup="isPopupOpen = false"
    />
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
import Skeleton from "@/components/Blocks/Skeleton";
import { mapGetters, mapState } from "vuex";
import PopupViewCertificate from "@/components/Popups/PopupViewCertificate";
import moment from "moment";

export default {
  name: "Achievment",
  components: { PopupViewCertificate, Skeleton, Button },
  data() {
    return {
      image: "",
      originalImage: "",
      isOriginalPictureLoading: true,
      isPopupOpen: false,
    };
  },
  props: {
    delete: Boolean,
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    async openPopup() {
      this.isPopupOpen = true;
    },
    openPopupDelete() {
      this.$store.commit("setCurrentPopup", {
        name: "PopupDeleteAchievement",
        isShown: true,
        props: {
          id: this.data?.id,
        },
      });
    },
  },
  computed: {
    imgSrc() {
      return this.data.image && `data:image/png;base64,${this.data.image}`;
    },
    imgSrcWithHash() {
      return this.data?.isPush
        ? this.data?.push_image
        : this.hashMedia
        ? this.imgSrc
        : this.data.url_original;
    },
    className() {
      return {
        "achievment--delete": this.delete,
        "achievment--new": this.data?.isPush,
      };
    },
    momentData() {
      return moment(this.data?.created_at).format("DD.MM.YYYY HH:mm:ss");
    },
    applicationsId() {
      if (this.data?.info_statements?.data?.length > 0) {
        return this.data.info_statements.data[0].statement_id;
      }
      return "";
    },
    ...mapState(["isDeleteBtnLoading"]),
    ...mapGetters(["hashMedia"]),
  },
};
</script>

<style lang="scss">
.achievment {
  display: grid;
  grid-template-columns: 150px minmax(150px, 200px) 1fr 1fr;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  transition: all 0.1s ease-in;
  border-top: 1px solid var(--background--btn--profile--hover);
  border-bottom: 1px solid var(--background--btn--profile--hover);
  @include link();
  @include adaptive(tablet-big) {
    grid-template-columns: 100px minmax(120px, 200px) 1fr 1fr;
  }
  @include adaptive(phone) {
    grid-template-columns: 100px minmax(80px, 1fr) 1fr 1fr;
    row-gap: 5px;
  }
  & a {
    text-decoration: none;
  }
  &:hover {
    background-color: var(--background--application--review);
    .achievment__btn {
      @media (min-width: 767px) {
        display: block;
      }
    }
  }
  &__img {
    position: relative;
    min-height: 150px;
    border-radius: 5px;
    cursor: pointer;
    .skeleton-basic {
      width: 150px;
      height: 150px;
      border-radius: 5px;
    }
    @include adaptive(tablet-big) {
      min-height: 100px;
      .skeleton-basic {
        width: 100px;
        height: 100px;
      }
    }
    @include adaptive(phone) {
      grid-row: 1 / 3;
      .skeleton-basic {
        grid-row: 1 / 3;
      }
    }
    & img {
      @include full-absolute();
      object-fit: cover;
      border-radius: 5px;
    }
  }
  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
    &--disabled {
      pointer-events: none;
      color: var(--event--hover) !important;
    }
    @include adaptive(phone) {
      font-size: 1.4rem;
      line-height: 1.7rem;
      align-self: center;
    }
  }
  &__date {
    font-weight: 500;
    justify-self: center;
    color: var(--banner--color);
    &--new {
      color: green;
    }
    @include adaptive(phone) {
      font-size: 1rem;
      line-height: 1.2rem;
      align-self: start;
      justify-self: start;
    }
    @include adaptive(phone) {
      align-self: center;
      grid-column: 3 / -1;
    }
  }
  &__btn {
    justify-self: end;
    display: none;
    @include adaptive(tablet-big) {
      display: block;
      padding: 6px 12px;
      font-size: 1.2rem;
    }
    @include adaptive(phone) {
      grid-column: 2 / -1;
    }
  }
  &--new {
    background-color: #e3f3e3;
    &:hover {
      background-color: #a8b3a8;
    }
  }
}
</style>
