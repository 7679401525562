<template>
  <div class="portfolio">
    <Title label="Портфолио" border />
    <div class="portfolio__title">Загрузить достижения</div>
    <p class="student-year__info">
      Все документы необходимо загружать с персонального компьютера или ноутбука
      во избежание технических неполадок
    </p>
    <PortfolioForm />
    <div class="portfolio__title">Загруженные достижения</div>
    <Preloader v-if="isPortfolioLoading" />
    <div class="portfolio__achievment" v-else>
      <Achievment
        v-for="item in portfolioList"
        :key="moment(item.created_at).unix()"
        :data="item"
        :isPictureLoading="isPictureLoading"
      />
    </div>
  </div>
</template>

<script>
import Achievment from "@/components/PortfolioSection/Achievment";
import Title from "@/components/Blocks/Title";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import { mapGetters, mapState } from "vuex";
import { getImg } from "@/utils/helpers";
import Preloader from "@/components/Blocks/Preloader";
import PortfolioForm from "@/components/PortfolioSections/PortfolioForm.vue";
import moment from "moment/moment";
export default {
  name: "Portfolio",
  data() {
    return {
      isPortfolioLoading: false,
      isPictureLoading: true,
    };
  },
  components: {
    PortfolioForm,
    Preloader,
    Title,
    Achievment,
  },

  watch: {
    portfolioListLength() {
      this.$store.commit("setPortfolioListLoaded", false);
    },
  },

  computed: {
    ...mapState([
      "portfolioList",
      "newPortfolioList",
      "portfolioListLength",
      "portfolioListLoaded",
    ]),
    ...mapGetters(["hashMedia"]),
  },
  methods: {
    loadPortfolioLit() {
      this.isPortfolioLoading = true;
      createRequest(requestConfigs.GETPortfolioList)
        .then((response) => {
          this.$store.commit("setPortfolioList", response.data);
          if (this.portfolioListLoaded) {
            this.$store.commit(
              "setNewPortfolioList",
              JSON.parse(JSON.stringify(response.data))
            );
          }
          this.$store.commit("setDocument", ["", "image"]);
        })
        .then(() => {
          this.$store.commit("setPortfolioListLoaded", true);
          this.portfolioList.forEach((item) => {
            if (this.hashMedia) {
              item.isPictureLoading = true;
              getImg(item.url_thumb)
                .then((result) => {
                  item.image = result;
                })
                .finally(() => {
                  this.isPictureLoading = false;
                });
            } else item.image = item.url_thumb;
          });
        })
        .finally(() => {
          this.isPortfolioLoading = false;
        });
    },
    moment,
  },
  mounted() {
    this.loadPortfolioLit();
  },
};
</script>

<style lang="scss"></style>
